<template>
  <div class="h-full text-nasida-green z-50 bg-white">
    <div id="header">
      <div class="flex items-center h-full justify-between container">
        <router-link
          @click="closeNavigation()"
          to="/"
          class="h-full cursor-pointer"
        >
          <!-- <img src="../../assets/images/nasida-logo.svg" alt="" /> -->
          <img src="../../assets/images/NASIDA-4yrs-logo.png" alt="NASIDA at 4yr" />
          <!-- <img src="../../ass÷ets/images/NASIDA-logo1.png" alt="" /> -->
          <!-- <img src="../../assets/images/nasida-logo.png" alt="" /> -->
        </router-link>

        <!-- Navigation -->
        <div class="navigation font-bold">
          <router-link to="/">HOME</router-link>
          <div
            class="flex items-center cursor-pointer"
            @mouseover="openDropDown(investInNasarawaLinks)"
          >
            <p>INVESTMENT</p>
            <div class="ml-2">
              <Icon
                icon="akar-icons:chevron-up"
                class="cursor-pointer text-dark-blue"
                height="16"
                width="16"
                v-if="
                  activeLinks.length == investInNasarawaLinks.length &&
                  isDropDownOpen
                "
              />
              <Icon
                icon="akar-icons:chevron-down"
                class="cursor-pointer text-dark-blue"
                height="16"
                width="16"
                v-else
              />
            </div>
          </div>
          <div
            class="flex items-center cursor-pointer"
            @mouseover="openDropDown(aboutUsLinks)"
          >
            <p>ABOUT US</p>
            <div class="ml-2">
              <Icon
                icon="akar-icons:chevron-up"
                class="cursor-pointer text-dark-blue"
                height="16"
                width="16"
                v-if="
                  activeLinks.length == aboutUsLinks.length && isDropDownOpen
                "
              />
              <Icon
                icon="akar-icons:chevron-down"
                class="cursor-pointer text-dark-blue"
                height="16"
                width="16"
                v-else
              />
            </div>
          </div>
           <div
            class="flex items-center cursor-pointer"
            @mouseover="openDropDown(resources)"
          >
            <p>RESOURCES</p>
            <div class="ml-2">
              <Icon
                icon="akar-icons:chevron-up"
                class="cursor-pointer text-dark-blue"
                height="16"
                width="16"
                v-if="
                  resources.length == resources.length && isDropDownOpen
                "
              />
              <Icon
                icon="akar-icons:chevron-down"
                class="cursor-pointer text-dark-blue"
                height="16"
                width="16"
                v-else
              />
            </div>
          </div>
          <div
            class="flex items-center cursor-pointer"
            @mouseover="openDropDown(events)"
          >
            <p>EVENTS</p>
            <div class="ml-2">
              <Icon
                icon="akar-icons:chevron-up"
                class="cursor-pointer text-dark-blue"
                height="16"
                width="16"
                v-if="
                    events.length == events.length && isDropDownOpen
                "
              />
              <Icon
                icon="akar-icons:chevron-down"
                class="cursor-pointer text-dark-blue"
                height="16"
                width="16"
                v-else
              />
            </div>
          </div>
          <!-- <router-link to="/press-releases">PRESS RELEASES</router-link> -->
          <router-link to="/contact-us">CONTACT US</router-link>
        </div>

        <div @click="toggleNavigation()" class="flex md:hidden">
          <Icon
            icon="icon-park-outline:hamburger-button"
            height="28"
            width="28"
            v-if="!isNavOpen"
            key="closed"
          />
          <Icon icon="bi:x-lg" height="24" width="24" v-else key="opened" />
        </div>
      </div>
    </div>

    <Dropdown
      :active="isDropDownOpen"
      :links="activeLinks"
      @close="closeDropDown()"
    />


    
    <!-- Mobile Navigation -->
    <div
      class="mobile-navigation"
      :class="{ 'mt-0': isNavOpen, '-mt-96': !isNavOpen }"
    >
      <div>
        <router-link
          class="font-bold"
          @click="closeNavigation()"
          to="/"
          >HOME</router-link
        >
      </div>
      <Accordion title="INVESTMENT" :spaced="true" :closed="true">
        <router-link @click="closeNavigation()" to="/why-nasarawa"
          >Why Nasarawa</router-link
        >
        <router-link @click="closeNavigation()" to="/investment-opportunities"
          >Investment Opportunities
        </router-link>

        <router-link @click="closeNavigation()" to="/doing-business"
          >Doing Business Here
        </router-link>
        <router-link @click="closeNavigation()" to="/#investment-opportunities"
          >Sectors</router-link
        >
        <!-- <router-link @click="closeNavigation()" to="/ppp"
          >PPP</router-link
        > -->
        <a href="https://ppp.nasida.na.gov.ng" target="_blank">PPP</a>
      </Accordion>

      <Accordion title="ABOUT US" :spaced="true" :closed="true">
        <router-link @click="closeNavigation()" to="/who-we-are"
          >Who We Are</router-link
        >
        <router-link @click="closeNavigation()" to="/our-governance"
          >Our Governance
        </router-link>
        <router-link @click="closeNavigation()" to="/departments"
          >Departments</router-link
        >
        
      </Accordion>
      <Accordion title="RESOURCES" :spaced="true" :closed="true">
        <router-link @click="closeNavigation()" to="/press-releases"
          >Press Release</router-link
        >
        <router-link @click="closeNavigation()" to="/incentives"
          >Incentive Inventory
        </router-link>
        <router-link @click="closeNavigation()" to="/summit-report"
          >Summit Report</router-link
        >
        <router-link @click="closeNavigation()" to="/documents"
          >Documents</router-link
        >
        <router-link @click="closeNavigation()" to="/business-reforms-2023"
          >Business Reforms 2023</router-link
        >
        <router-link @click="closeNavigation()" to="/business-related-regulations"
          >Business Related Regulations</router-link
        >
        <router-link @click="closeNavigation()" to="/nasida-2021-2024-report"
          >NASIDA 2021-2024 Report</router-link
        >
        <!-- <router-link @click="closeNavigation()" to="/procedures-for-approvals"
          >Procedures for Approvals</router-link
        > -->
      </Accordion>
      
      <Accordion title="EVENTS" :spaced="true" :closed="true">
        <router-link @click="closeNavigation()" to="/investment-summit"
          >NIS</router-link
        >
        <router-link @click="closeNavigation()" to="/nasbet"
          >NASBET
        </router-link>
      </Accordion>
      <!-- <div>
        <router-link
          class="font-bold"
          @click="closeNavigation()"
          to="/press-releases"
          >PRESS RELEASES</router-link
        >
      </div> -->
      <div>
        <router-link
          class="font-bold"
          @click="closeNavigation()"
          to="/contact-us"
          >CONTACT US</router-link
        >
      </div>
    </div>
  </div>
</template>

<script src="./header.component.js"></script>
<style src="./header.component.css" scoped lang="css"></style>

